import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export default () => {
  const { imgIndispensible } = useStaticQuery(graphql`
    {
      imgIndispensible: file(
        relativePath: {
          eq: "indispensible/infoscreen_kinder_und_arzneimittel_1920x1080_183.jpg"
        }
      ) {
        id
        relativePath
        childImageSharp {
          gatsbyImageData(height: 500, quality: 95, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <ImgContainer>
      <GatsbyImage
        image={imgIndispensible.childImageSharp.gatsbyImageData}
        alt="Einfach unverzichtbar! Ihre Apotheke vor Ort."
      />
    </ImgContainer>
  );
};

const ImgContainer = styled.div`
  width: 100%;
  max-height: 500px;
`;
