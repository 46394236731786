import { merge } from 'lodash';

const proxyUrl = 'https://api.hirsch-apotheke-korbach.de/proxy.php/';
const proxyAuthKey = '46jZqmP97Hm4dA6EdYGbtGNazawgUxjv';

function createDefaultOptions(targetUrl: string) {
  return {
    headers: {
      'X-Proxy-Auth': proxyAuthKey,
      'X-Proxy-Target-Url': targetUrl,
    },
  };
}

/**
 * add target url as GET param to properly handle caching
 * @param targetUrl target url to request via proxy
 * @returns proxy url with target url as GET param
 */
function createRequestUrl(targetUrl: string) {
  return `${proxyUrl}/?target=${targetUrl}`;
}

// eslint-disable-next-line no-undef
export function fetchViaProxy(url: string, options: RequestInit = {}) {
  return fetch(
    createRequestUrl(url),
    merge(options, createDefaultOptions(url)),
  );
}
