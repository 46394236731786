import React from 'react';
import styled from 'styled-components';
import ExternalLink from './ExternalLink';
import imgShop from '../assets/Rezept-Upload-iade.svg';

export default () => {
  return (
    <ImgContainer>
      <ExternalLink href="https://ihreapotheken.de/apotheke/hirsch-apotheke-korbach-34497-315">
        <Img src={imgShop} alt="Jetzt Rezept online bei uns einlösen! ia.de" />
      </ExternalLink>
    </ImgContainer>
  );
};

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const Img = styled.img`
  width: 200px;
  height: 200px;
`;
