import { queryTextContent } from '../utils/queryTextContent';
import { fetchViaProxy } from './proxy.service';

export interface RssItem {
  title: string;
  date: Date;
  link: string;
  imgSrc?: string;
  imgObjectUrl?: string;
  description: string;
}

export default {
  parseFeed(url: string, limit?: number): Promise<RssItem[]> {
    return (
      fetchViaProxy(url)
        .then((response) => response.text())
        .then((xmlString) => {
          const domParser = new DOMParser();
          const doc = domParser.parseFromString(xmlString, 'text/xml');

          return Array.from(doc.querySelectorAll('item'))
            .map((item) => {
              // extract title
              const title = queryTextContent(item, 'title');
              // extract link url
              const link = queryTextContent(item, 'link');
              // extract date
              const dateString = queryTextContent(item, 'pubDate');
              const dateTimestamp = Date.parse(dateString);
              if (Number.isNaN(dateTimestamp)) {
                console.warn('Unable to parse date field', `"${dateString}"`);

                return undefined;
              }

              const date = new Date(dateTimestamp);

              // extract image src
              const imgSrcNode = item.querySelector('enclosure');
              const imgSrc = imgSrcNode?.attributes?.getNamedItem('url')?.value;

              // extract description
              const descriptionHtmlString = queryTextContent(
                item,
                'description',
              );
              const description = domParser.parseFromString(
                descriptionHtmlString,
                'text/html',
              ).body.textContent;

              return {
                title,
                link,
                date,
                imgSrc,
                description,
              } as RssItem;
            })
            .filter((item): item is RssItem => !!item);
        })
        // limit items
        .then((items) => (limit ? items.slice(0, limit) : items))
        // load images via proxy
        .then((items) => {
          const result = items.map(async (item) => {
            return {
              ...item,
              imgObjectUrl: item.imgSrc
                ? await fetchViaProxy(item.imgSrc)
                    .then((response) => response.blob())
                    .then((imageBlob) => {
                      try {
                        // eslint-disable-next-line compat/compat
                        return URL.createObjectURL(imageBlob);
                      } catch (err) {
                        return undefined;
                      }
                    })
                    .catch(() => {
                      return undefined;
                    })
                : undefined,
            } as RssItem;
          });
          return Promise.all(result);
        })
    );
  },
};
