import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Box } from 'grommet';
import { Helmet } from 'react-helmet';
import rssService, { RssItem } from '../services/rss.service';
import ExternalLink from './ExternalLink';
import { red, grey } from '../utils/colors';

const rssUrl = 'http://www.aponet.de/nachrichten/rss.rdf';
const rssItemLimit = 5;

export default () => {
  const [newsItems, setNewsItems] = useState<RssItem[] | null>(null);
  useEffect(() => {
    rssService
      .parseFeed(rssUrl, rssItemLimit)
      .then((items) => setNewsItems(items));
  }, []);

  if (!newsItems) return null;

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://www.aponet.de" />
      </Helmet>
      <h2>Aktuelles</h2>
      <Box wrap direction="column" alignContent="center">
        {newsItems.map((newsItem) => (
          <NewsBox href={newsItem.link} key={newsItem.link}>
            <div>
              <NewsDate>
                {format(new Date(newsItem.date), 'dd.MM.yyyy')}
              </NewsDate>
              <NewsTitle>{newsItem.title}</NewsTitle>
            </div>
            <div>
              {newsItem.imgObjectUrl ? (
                <NewsImg src={newsItem.imgObjectUrl} width={57} height={43} />
              ) : (
                <NewsImgPlaceholder />
              )}
              <NewsDescription>{newsItem.description}</NewsDescription>
            </div>
          </NewsBox>
        ))}
      </Box>
    </div>
  );
};

const NewsBox = styled(ExternalLink)`
  display: block;
  width: 500px;
  max-width: 100%;
  padding: 5px;
  border-radius: 5px;
  color: inherit;
  text-align: left;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: none;
    h6 {
      color: ${red};
      text-decoration: underline;
    }
  }
`;

const NewsDate = styled.span`
  color: ${grey};
  font-size: 0.7em;
`;

const NewsTitle = styled.h6`
  margin: 0;
`;

const NewsImg = styled.img`
  margin: 3px 5px 0 0;
  float: left;
`;

const NewsImgPlaceholder = styled.div`
  width: 57px;
  height: 43px;
  margin: 3px 5px 0 0;
  float: left;
`;

const NewsDescription = styled.p`
  margin: 0 5px;
  font-size: 0.8em;
  text-align: left;
`;
