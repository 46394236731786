import styled from 'styled-components';

export default styled.div`
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 20px;
  &:last-child {
    border-bottom: none;
  }
`;
