import Month from '../models/months';
import MonthlyOffer from '../models/monthly-offer';

const offersCollection: MonthlyOffer[] = [
  // ##### TEMPLATE
  // {
  //   month: Month.XX,
  //   year: 2024,
  //   offers: [
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //   ],
  // },
  // #####
  {
    month: Month.October,
    moreMonths: [
      {
        year: 2024,
        month: Month.September,
      },
    ],
    year: 2024,
    offers: [
      {
        title: 'Otriven',
        description: 'Nasenspray, 10 ml',
        imgFile: 'otriven.png',
        offerPrice: 4.49,
      },
      {
        title: 'Sinupret extract',
        description: 'bei Sinusitis, 20 Tabletten',
        imgFile: 'sinupret-extract.png',
        offerPrice: 12.99,
      },
      {
        title: 'Gutschein',
        description:
          'Gutschein für eine Blutdruckmessung in der deutschen Herz-Woche (30.09.bis 05.10.2024).',
        imgFile: 'sphygmomanometer-915652.png',
        // offerPrice: 0.0,
      },
    ],
  },
  {
    month: Month.November,
    year: 2024,
    offers: [
      {
        title: 'Aspirin complex',
        description: 'bei Schnupfen & Fieber, 20 Beutel',
        imgFile: 'bayer-aspirin-complex-granulat-20-stk.jpg',
        offerPrice: 13.99,
      },
      {
        title: 'Mucosolvan',
        description: 'gegen Husten, 10 Retardkapseln',
        imgFile: 'mucosolvan_10_retardkapseln.png',
        offerPrice: 9.99,
      },
      {
        title: 'Gaviscon dual',
        description: 'bei Sodbrennen, 12 x 10 ml Beutel',
        imgFile: 'gaviscom_dual_500mg_12beutel.png',
        offerPrice: 9.99,
      },
    ],
  },
];

export default offersCollection;
