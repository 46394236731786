import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { redDark, red } from '../utils/colors';

interface ExternalLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
}

export default ({ href, className, children }: ExternalLinkProps) => (
  <StyledAnchor
    className={className}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </StyledAnchor>
);

const StyledAnchor = styled.a`
  color: ${redDark};
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: ${red};
    text-decoration: underline;
  }
`;
